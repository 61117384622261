<template>
    <div id="age_gate" v-if="open">
        <div class="panel">
            <span class="circle">18</span>
            <h3>Elmúltál 18 éves?</h3>
            <p>
                Ez a tartalom kizárólag nagykorú személyek számára nyújt tájékoztatást! Az "Igen" gombra kattintással kijelented, hogy elmúltál 18 éves.
            </p>
            <span class="btn" @click="setCookie">Igen, belépek</span>
            <!--span class="btn">Nem</span-->
        </div>
    </div>
</template>

<script>

export default {
    name: 'ageGate',
    data(){
        return {
            open: false
        }
    },
    mounted() {
        //this.cookieCheck();
        this.cookieCheck();
    },
    methods: {
        setCookie(){
            this.$cookies.set('age_gate', 1);
            if(this.$cookies.get('age_gate')){
                this.open = false;
            }
        },
        cookieCheck(){
            if(!this.$cookies.get('age_gate')){
                this.open = true;
            }
        }
    }
}
</script>
