<template>
  <ageGate></ageGate>
  <router-view/>

</template>
<script>
  import AgeGate from "./components/ageGate";
  export default {
    components: {AgeGate}
  }
</script>
<!-- OneTrust Cookies Consent Notice end for dobjle100at.hu -->
