<template>
  <section id="wellcome" class="screen" style="background-image: url('//fustmenteskalkulator.hu/img/backgrounds/001_HOME/index.jpg');">
    <div class="container" >
      <div class="inner">
        <div class="panel bg-white">
          <div class="logos">

            <img src="../assets/futmentes_varos_logo.svg" alt="" class="logo-main">
            <img src="../assets/fustmentes_logo.png" alt="" class="logo">
          </div>
          <h1>Füstmentes <span>Kalkulátor</span></h1>
          <h3>
            A kalkulátor használatával egyszerűen kiszámíthatja azt a CO2-mennyiséget (kilogrammban kifejezve), amelyet a különféle tevékenységeivel és fogyasztásaival a városban élve egy év alatt a környezetbe bocsát.
          </h3>
          <router-link to="/teszt" class="button start-btn">Kitöltöm a tesztet</router-link>

        </div>

        <div class="panel col-2 bg-green row-2">
          <div class="col text-container">
            <p>
              Mivel a globális éghajlatváltozás elsődleges oka a légkör üvegház-gáz (elsősorban CO2-) koncentrációjának a civilizációs hatások általi megemelése,
              <strong>
                a kalkulátor azt is megmutatja, hogy a kitöltő személy mennyivel járul hozzá egy év alatt a földi légkörben lévő üvegházgázok mennyiségének növekedéséhez, illetve mekkora
                kibocsátáscsökkenést tud elérni a szokásai megváltoztatásával a városban élve.
              </strong>
            </p>
            <p>
              <strong>
                VÁLASZD VÁROSLAKÓKÉNT A KISEBB SZÉNDIOXID-KIBOCSÁTÁSSAL JÁRÓT, A KEVÉSBÉ ÁRTALMASAT!
              </strong>
            </p>
          </div>
          <div class="col icon-container">
            <img src="../assets/city.svg" alt="">
          </div>
        </div>

      </div>
      <footer>
        <div class="inner">
          <span>Copyright Philip Morris International 2021 </span>
          <router-link to="/impresszum">Impresszum</router-link>
        </div>
      </footer>
    </div>

  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  methods: {
    test(){
        this.$store.dispatch('test');
    }
  }
}
</script>
